/* fonts */
@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Extra Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Bold.otf');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Book Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Oblique.ttf');
  font-weight: 400;
  font-style: italic;
}

/* style */
body,
html,
#root {
  height: 100%;
  font-family: 'ITC Avant Garde Gothic';
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
  touch-action: manipulation;
}

.bg-light {
  background-image: url(/public/images/background.png);
  background-repeat: repeat-y;
  background-size: cover;
}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
  padding-bottom: 10rem !important;
}

h1,
h5,
h6 {
  font-weight: bold;
  line-height: 1.5;
}

.form-group {
  min-width: 100px !important;
  margin-left: auto;
  margin-right: auto;
}

.form-group label {
  font-weight: bold;
}

.rounded h6 {
  color: #00567d;
}

.bg-login {
  background: url('/public/images/login_bg.jpg') no-repeat center center fixed;
  background-size: cover;
}

.bg-main {
  background: linear-gradient(45deg, #FFD800, #66cdcc);
}

.bg-vw-red {
  background-color: #d60000;
}

.bg-vw-dark {
  background-color: #00567d;
}

.bg-vw-darkest {
  background-color: #00567d;
}

.bg-vw-light {
  background-color: #2dccd3;
}

.bg-vw-excel {
  background-color: #297148;
}

.text-vw-dark {
  color: rgb(0, 90, 125);
}

.text-vw-light {
  color: rgb(45, 204, 211);
}

.logo {
  height: 40px;
}

.hover:hover {
  cursor: pointer;
  color: #fc9700;
}

.table {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.table-clickable tr {
  cursor: pointer;
  background-color: #fff;
}

.table-clickable tr:hover {
  background-color: #2dccd3 !important;
  color: #fff !important;
}

th,
td {
  text-align: center !important;
  vertical-align: middle !important;
  width: 100px;
}

.thead-dark th {
  background-color: #00567d !important;
  border-color: #ffffff !important;
}

th input, select {
  border: 2px solid #2dccd3;
  border-radius: 5px;
  text-align: center;
}

.thead-manual {
  background-color: #fdc5c5 !important;
  font-size: 9pt;
}

.thead-clients {
  background-color: #c5dbfd !important;
}

.thead-totals {
  background-color: #ffb100c4 !important;
}

.list-undecorated {
  list-style-type: none;
  padding: 0;
}

.text-break {
  white-space: pre-line;
  word-break: break-all;
}

.btn-multi-line {
  white-space: normal; /* Allows text wrapping */
  line-height: 1.5;   /* Adjusts line spacing for wrapped text */
  height: auto;       /* Adjusts height automatically based on content */
  padding: 0.5rem 1rem; /* Optional: Adjust padding to ensure text fits well */
}

@media (max-width: 768px) {
  .btn-multi-line {
    margin-top: 5px;
  }
  .btn-group {
    flex-direction: column; /* Stack buttons vertically */
    width: 100%;            /* Full width to use all available space */
  }
}

.text-monospace {
  font-family: monospace;
}

.box-shadow {
  /*box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);*/
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191 / 25%);
}

.box-iconed {
  min-height: 100px;
  text-align: center;
}

.box-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.badge {
  color: #ffffff;
}

.save {
  position: fixed;
  bottom: 0;
  width: 10vw;
  height: 3vw;
  font-size: 20px;
  left: 45%;
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
  background-color: #2dccd3;
  /* Change to your preferred background color */
  color: white;
  border: none;
  border-radius: 5px;
  /* Rounded corners for a sleek look */
}

.save:hover {
  transform: translateY(-5px);
  /* Slightly raise the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add shadow for 3D effect */
  background-color: #00567d;
  /* Slightly darken the background color on hover */
  cursor: pointer;
}

@media (min-width: 1200px) {
  .container {
    max-width: 90vw !important;
  }
}

@media (max-width: 1200px) {
  .save {
    position: fixed;
    bottom: 0;
    width: 30vw;
    height: 15vw;
    font-size: 20px;
    left: 35%;
  }
}

.btn-outline-vacway {
  background-color: transparent !important;
  background-image: none !important;
  border-color: #f8f9fa !important;
  margin-right: 1vw;
  margin-left: 1vw;
}

.btn-outline-vacway:hover {
  background-color: #2dccd3 !important;
  background-image: none !important;
  border-color: #2dccd3 !important;
}

.btn-remoteAction {
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 225px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .btn-outline-vacway {
    background-color: transparent !important;
    background-image: none !important;
    border-color: #f8f9fa !important;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 3vw;
    margin-top: 3vw;
  }
}

.btn-outline-dark:hover {
  background-color: transparent !important;
  background-image: none !important;
  border-color: #00567d !important;
}

.topButton {
  width: 50px;
  height: 50px;
  border: 2px solid #2dccd3;
  background-color: #00567d;
  color: #fff;
  border-radius: 90px;
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  position: fixed;
  bottom: 25px;
  right: 25px;
  text-align: center;
  cursor: pointer;
  font-size: xx-large;
  z-index: 10;
}

.topButton:hover {
  background-color: #2dccd3;
}

.topButton:active {
  background-color: #00567d;
}

.user-icon {
  width: 25px;
  height: 25px;
  border: 1px solid #fff;
  border-radius: 60px;
}

.user-image {
  width: 150px;
  height: 150px;
  border: 1px solid #fff;
  border-radius: 150px;
}

.machinelistlanguage {
  width: 25px;
  height: 25px;
}

@keyframes illuminateBorder {
  0% {
    box-shadow: 0 0 10px rgba(0, 217, 255, 0.5);
  }

  100% {
    box-shadow: 0 0 30px rgba(255, 166, 0, 0.9);
  }
}

.illuminated-border {
  animation: illuminateBorder 2s infinite alternate;
}

.floating-message {
  position: absolute;
  background-color: #fff;
  /* Set the background color */
  border: 1px solid #ddd;
  /* Set the border color */
  box-shadow: 0 2px 4px rgba(83, 83, 83, 0.1);
  /* Add a subtle shadow */
  padding: 10px;
  border-radius: 4px;
  z-index: 999;
  /* Ensure it appears above other elements */
  transform: translateX(-50%);
  /* Center the message horizontally */
  color: #00567d;
  width: 250px;
}

/* Add animation for a smooth appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.floating-message {
  animation: fadeIn 0.5s ease-in-out;
}


/*---------------------LOCKERS GUI---------------------*/

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #2dccd3 !important;
}

/* S */

#lockerBNS {
  width: 45px;
  height: 45px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNS {
  width: 45px;
  height: 45px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBWS {
  width: 60px;
  height: 45px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBWS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBWS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCWS {
  width: 60px;
  height: 45px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCWS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCWS p {
  font-size: 12pt;
  color: #ffffff;
}

/* M */

#lockerBNM {
  width: 45px;
  height: 90px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNM {
  width: 45px;
  height: 90px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBWM {
  width: 60px;
  height: 90px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBWM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBWM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCWM {
  width: 60px;
  height: 90px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCWM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCWM p {
  font-size: 12pt;
  color: #ffffff;
}

/* L */

#lockerBNL {
  width: 45px;
  height: 135px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNL {
  width: 45px;
  height: 135px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNL p {
  font-size: 12pt;
  color: #ffffff;
}

/* XL */

#lockerBNXL {
  width: 45px;
  height: 180px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNXL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNXL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNXL {
  width: 45px;
  height: 180px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNXL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNXL p {
  font-size: 12pt;
  color: #ffffff;
}

/* 2XL */

#lockerBN2XL {
  width: 45px;
  height: 225px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBN2XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBN2XL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCN2XL {
  width: 45px;
  height: 225px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCN2XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCN2XL p {
  font-size: 12pt;
  color: #ffffff;
}

/* 3XL */

#lockerBN3XL {
  width: 45px;
  height: 270px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBN3XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBN3XL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCN3XL {
  width: 45px;
  height: 270px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCN3XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCN3XL p {
  font-size: 12pt;
  color: #ffffff;
}

/* Kiosc */

#lockerEmpty {
  width: 44px;
  height: 44px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerKioskLeft {
  width: 45px;
  height: 135px;
  margin-right: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_left.png);
  background-size: cover;
}

#lockerKioskRight {
  width: 45px;
  height: 135px;
  margin-left: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_right.png);
  background-size: cover;
}

/* STATUS */

#AV {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#AV p {
  opacity: 0;
}

#FB {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a7aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#FB p {
  opacity: 0;
}

#FL {
  width: 100%;
  height: 100%;
  top: -40px;
  position: relative;
  background: url(/public/images/cross.png);
  background-size: 100% 100%;
  z-index: -1;
}

#FL p {
  opacity: 0;
}

#RK {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RK p {
  opacity: 0;
}

#RM {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RM p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RQ {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RQ p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RP p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RA {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RA p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#BL {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #b500fc;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#BL p {
  opacity: 0;
}

#Null {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #0e0e0e;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#Null p {
  opacity: 0;
}

/* RSSI SIGNAL STATUS */


#LOWTOP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #b74aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOWTOP p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#LOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4d4aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#LOWHIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a83ff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOWHIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MIDLOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4accff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MIDLOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MID {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4affba;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MID p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MIDHIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #6eff4a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MIDHIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGHLOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ffed4a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGHLOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ffa14a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGHTOP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ff504a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGHTOP p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#lockerMatrix {
  height: 280px;
  flex-direction: column;
  align-content: flex-start;
}

#indicatorNull {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #0e0e0e;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorAvailable {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorRented {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorError {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorAdmin {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a7aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorBattery {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #dd0202;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#kiosk {
  background: url(/public/images/machines/big.png);
  background-size: cover;
  width: 126px;
  height: 277px;
  margin-top: 0;
  position: absolute;
  margin-left: 489px;
  margin-right: auto;
  z-index: 1;
}

#matrixContainer {
  overflow: auto;
  margin: 0 auto;
  display: table;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  #matrixContainer {
    overflow: auto;
    margin: 0 auto;
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
  }
}

#matrixScroll {
  overflow-x: scroll;
}

#matrixScroll::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

#matrixScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}

#matrixScroll::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: none;
  /* removes the border around the thumb */
}

#matrixScroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* color of the scroll thumb when hovering */
}

#totalMatrixContainer {
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #2dccd3; */
  padding: 1vw 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.totalMatrixIndicators {
  text-align: center;
  padding: 10px 30px 10px 30px;
  /* padding-right: 1vw; */
  border-left: 2px solid #00567d;
  border-right: 2px solid #00567d;
  border: 2px solid #2dccd3;
  margin: 10px;
  background-color: #00567d;
  color: #ffffff;
  border-radius: 10px;
}

.complete-width {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.form-group {
  min-width: 100px !important;
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.button-center {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1vw;
}

/* ANIMACIÓN DE CARGA DE TABLAS */

/* ANIMACIÓN DE CARGA DE TABLAS */

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #2dccd3;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.spinner img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.spinner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-right-color: #00567d;
  border-left-color: #00567d;
  animation: spin 1s linear infinite;
  z-index: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* PROMOTERS TABLE */

.timetable h6 {
  margin: 5px;
  width: 100px;
  margin-left: 15px;
}

.timetable label {
  margin: 5px;
  width: 100px;
  text-align: center;
}

.timetable .form-group {
  text-align: center !important;
  max-width: 100px;
  margin-left: 5px;
}

/* MANUAL EXPEDITIONS HOME */

.bg-light-blue {
  background-color: #2dccd3 !important;
}

.bg-light-blue h5 {
  color: #ffffff !important;
}

.bg-light-blue h6 {
  color: #ffffff !important;
}

.bg-light-blue p {
  color: #ffffff !important;
}

.bg-light-blue .text-muted {
  color: #ffffff !important;
}

.bg-light-blue .bg-vw-light {
  background-color: #ffffff !important;
}


/* TEST 404 STARNGER THINGS*/

.c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}

._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}

._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}

._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}

.text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}

.right {
  float: right;
  width: 60%;
}

.cloud {
  width: 350px;
  height: 120px;

  background: #2dccd3;
  background: linear-gradient(to top, #2dccd3 100%);
  background: -webkit-linear-gradient(to top, #2dccd3 100%);
  background: -moz-linear-gradient(to top, #2dccd3 100%);
  background: -ms-linear-gradient(to top, #2dccd3 100%);
  background: -o-linear-gradient(to top, #2dccd3 100%);

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;

  position: absolute;
  margin: 120px auto 20px;
  z-index: -1;
  transition: ease 1s;
}

.cloud:after,
.cloud:before {
  content: '';
  position: absolute;
  background: #2dccd3;
  z-index: -1
}

.cloud:after {
  width: 100px;
  height: 100px;
  top: -50px;
  left: 50px;

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.cloud:before {
  width: 180px;
  height: 180px;
  top: -90px;
  right: 50px;

  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
}

.x1 {
  top: -50px;
  left: 100px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.9;
  -webkit-animation: moveclouds 15s linear infinite;
  -moz-animation: moveclouds 15s linear infinite;
  -o-animation: moveclouds 15s linear infinite;
}

.x1_5 {
  top: -80px;
  left: 250px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-animation: moveclouds 17s linear infinite;
  -moz-animation: moveclouds 17s linear infinite;
  -o-animation: moveclouds 17s linear infinite;
}

.x2 {
  left: 250px;
  top: 30px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x3 {
  left: 250px;
  bottom: -70px;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.8;

  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x4 {
  left: 470px;
  botttom: 20px;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.75;

  -webkit-animation: moveclouds 18s linear infinite;
  -moz-animation: moveclouds 18s linear infinite;
  -o-animation: moveclouds 18s linear infinite;
}

.x5 {
  left: 200px;
  top: 300px;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.8;

  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-moz-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-o-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

/*RESPONSIVE*/

@media (max-width: 1200px) {

  td,
  th {
    font-size: 8px;
    width: auto;
  }

  .complete-width {
    width: auto !important;
    margin-right: 0;
    margin-left: 0;
  }
}

/*PAGINATION INDEX*/

#page-numbers {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100vw;
  flex-wrap: wrap;
  padding: 10px;
}

#page-numbers li {
  padding: 5px 10px;
  border: 1px solid #2dccd3;
  color: #00567d;
  list-style-type: none;
  margin: 10px;
  border-radius: 5px;
}

#page-numbers li.active {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}

#page-numbers li:hover {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}

/* ARQUEOS CALENDAR */

.calendar-container {
  display: flex;
  flex-wrap: wrap;
}

.calendar-year {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.25rem !important;
}

.calendar-month {
  margin-bottom: 20px;
  width: 238px;
  margin-left: 20px;
}

.calendar-day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ccc;
  margin: 2px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.calendar-weekday {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  margin: 2px;
  cursor: pointer;
  color: #2dccd3;
}

.calendar-spacer {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: none;
  margin: 2px;
  cursor: pointer;
  color: #fff;
}

.calendar-day:hover {
  background-color: #f0f0f0;
}

.has-arqueo {
  background-color: green;
  color: white;
}

.no-shift {
  background-color: rgb(224, 87, 77);
  color: white;
}

.mid-shift {
  background-color: rgb(255, 174, 0);
  color: white;
}

.complete-shift {
  background-color: green;
  color: white;
}

.holiday-shift {
  background-color: rgb(0, 111, 175);
  color: white;
}

.calendar-header {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ccc;
  margin: 2px;
  background-color: #f0f0f0;
}

.calendar-year h2 {
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.calendar-month h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

/* FORM LOADING WHEEL */

.loading-wheel {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  /* Change the color as desired */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* DRAGON */

.dragon-gif {
  width: 500px;
  bottom: 0;
  position: fixed;
  z-index: 10;
}

/* SHIFT KEYPAD  */

.keypad-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.keypad-button {
  width: 17vw;
  height: 17vw;
  font-size: 10vw;
  text-align: center;
  background-color: #515151;
  color: #fff;
  border: 2px solid #2dccd3;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  line-height: 17vw;
}

.keypad-button:active {
  transform: translateY(3px);
}

.keypad-button-delete {
  width: 17vw;
  height: 17vw;
  font-size: 10vw;
  text-align: center;
  background-color: #d80000;
  color: #fff;
  border: 2px solid #ff0000;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  line-height: 17vw;
}

.keypad-button-delete:active {
  transform: translateY(3px);
}

.keypad-button-back {
  width: 17vw;
  height: 17vw;
  font-size: 10vw;
  text-align: center;
  background-color: rgb(255, 166, 0);
  color: #fff;
  border: 2px solid #fcc100;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  line-height: 17vw;
}

.keypad-button-back:active {
  transform: translateY(3px);
}

.entered-code {
  margin-bottom: 20px;
  font-size: 7vw;
  font-weight: bold;
  background-color: #fff;
  border-radius: 20px;
  border: 2px solid #2dccd3;
  width: 94%;
  height: 11vw;
  text-align: center;
  margin-left: 3%;
  margin-right: 3%;
}

.keypad-wrapper {
  background-color: #2d2d2d;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #2dccd3;
  width: 80vw;
}

.submit-button {
  width: 94%;
  height: auto;
  font-size: 7vw;
  background-color: #2dccd3;
  color: #000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  -webkit-transition: background-color .3s, -webkit-transform .3s;
  transition: background-color .3s, -webkit-transform .3s;
  -o-transition: background-color .3s, transform .3s;
  transition: background-color .3s, transform .3s;
  transition: background-color .3s, transform .3s, -webkit-transform .3s;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 20px;
  font-weight: 700;
  padding: 30px;
}

.submit-end-button {
  width: 94%;
  height: auto;
  font-size: 7vw;
  background-color: rgb(255, 0, 0);
  color: #000;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  -webkit-transition: background-color .3s, -webkit-transform .3s;
  transition: background-color .3s, -webkit-transform .3s;
  -o-transition: background-color .3s, transform .3s;
  transition: background-color .3s, transform .3s;
  transition: background-color .3s, transform .3s, -webkit-transform .3s;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 20px;
  font-weight: 700;
  padding: 30px;
}

.keypad-success-animation {
  width: 25vw;
  margin-right: 50px;
  margin-left: 40px;
  height: 25vw;
  padding: 10px;
}

.success-message {
  width: 90%;
  font-size: 4vw;
  font-weight: 700;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* ProgressBar.css */
.progress-container {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
}

.progress-bar {
  height: 20px;
  width: 0;
  border-radius: 8px;
  transition: width 1s ease-in-out;
  /* Animation effect */
}

.progress-percentage {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  /* Center vertically */
  color: #000;
  /* Adjust color as needed */
}

.progress-container-promoter {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 40px;
  overflow: hidden;
  min-width: 100px;
}

.progress-bar-promoter {
  height: 45px;
  width: 0;
  border-radius: 40px;
  transition: width 1s ease-in-out;
  /* Animation effect */
}

.progress-percentage-promoter {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  /* Center vertically */
  color: #000;
  /* Adjust color as needed */
  font-size: 24pt;
}

/* FORM LOADING WHEEL */

.loading-wheel {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  /* Change the color as desired */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* SLIDER SHIFT */

.slider-container {
  position: relative;
  width: 100%;
  height: 47px;
  background-color: rgb(221, 221, 221);
  border-radius: 40px;
  border: 1px solid #2dccd3;
}

.slider {
  position: absolute;
  width: 45px;
  height: 45px;
  background-color: rgb(0, 86, 125);
  border-radius: 50%;
  text-align: center;
  line-height: 45px;
  color: white;
  left: 0px;
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.47);
  -moz-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.47);
}

.centered-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-modal .modal-dialog {
  margin: auto;
}